<script setup lang="ts">
const { getFormattedPrice } = usePrice();
const props = defineProps<{
  value: number | undefined;
}>();

const getPrice = computed<string>(() => getFormattedPrice(props.value));
</script>

<template>
  <p class="">
    <slot name="beforePrice" />
    <span>{{ getPrice.replace(',', '.') }}</span>
    <slot name="afterPrice" />
  </p>
</template>
