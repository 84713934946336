<script setup lang="ts">
import {
  getCategoryRoute,
  getTranslatedProperty,
} from "@shopware/helpers";
import type { Schemas } from "#shopware";
import { onKeyStroke, useActiveElement } from "@vueuse/core";
import {
  useCachedCmsElementsFromCategoryWithChilds
} from "~/components/server/useCachedCmsElementsFromCategoryWithChilds";
type NavigationElement = Schemas["Category"] & {
  activeClass?: boolean;
};

const { navigationElements } = useNavigation();
const currentMenuPosition = ref<string | undefined>(undefined);
const resetActiveClass = ref<boolean>(true);

const emit = defineEmits({
  close: () => {
    return true;
  }
})

const route = useRoute();
const localePath = useLocalePath();
const { formatLink } = useInternationalization(localePath);

const categoryId = "01912be66e51779e8063bc45d89c6487";
const categoryData = await useCachedCmsElementsFromCategoryWithChilds(categoryId);
const subCategories = categoryData[0]?.children;

onMounted(() => {
  let currentNaviagtionElement: NavigationElement | undefined;
  if (navigationElements.value) {
    currentNaviagtionElement = findNavigationElement(
      route.path.slice(1),
      navigationElements.value,
    );
  }
  if (currentNaviagtionElement) {
    updateActiveClass(
      currentNaviagtionElement.id,
      currentNaviagtionElement.parentId,
    );
  }

  setMenuSize();
  window.addEventListener('resize', setMenuSize);
});

const findNavigationElement = (
  routePath: string,
  navigation: NavigationElement[],
): NavigationElement | undefined => {
  for (let i = 0; i < navigation.length; i++) {
    const navigationElement = navigation[i];
    const seoUrls = navigationElement.seoUrls as
      | Schemas["SeoUrl"][]
      | undefined;
    if (seoUrls) {
      for (let j = 0; j < seoUrls.length; j++) {
        const currentSeoUrl = seoUrls[j];
        if (currentSeoUrl && currentSeoUrl.seoPathInfo === routePath) {
          return navigationElement;
        }
      }
    }
    const children = navigationElement.children;
    if (children) {
      const foundElement = findNavigationElement(routePath, children);
      if (foundElement) {
        return foundElement;
      }
    }
  }
  return undefined;
};

const onUpdateActiveClass = (
  navigationId: string,
  parentId: string | undefined,
) => {
  updateActiveClass(navigationId, parentId);
};

const resetNavigationActiveClass = (navigation: NavigationElement[]) => {
  for (let ni = 0; ni < navigation.length; ++ni) {
    navigation[ni].activeClass = false;
    const children = navigation[ni].children;
    if (children) {
      resetNavigationActiveClass(children);
    }
  }
};

const updateActiveClass = (
  navigationId: string,
  parentId: string | undefined,
) => {
  const setNavigationActiveClass = (
    navigation: NavigationElement[],
    navigationId: string,
    parentId: string | undefined,
  ) => {
    for (let ni = 0; ni < navigation.length; ++ni) {
      if (navigation[ni].id === navigationId) {
        navigation[ni].activeClass = true;
      }
      if (navigation[ni].id == parentId) {
        navigation[ni].activeClass = true;
        if (navigationElements.value) {
          setNavigationActiveClass(
            navigationElements.value,
            navigationId,
            navigation[ni].parentId,
          );
        }
      }
      const children = navigation[ni].children;
      if (children) {
        setNavigationActiveClass(children, navigationId, parentId);
      }
    }
  };

  if (navigationElements.value) {
    resetNavigationActiveClass(navigationElements.value);
    setNavigationActiveClass(navigationElements.value, navigationId, parentId);
    resetActiveClass.value = false;
  }

};
//#region - keyboard navigation (highliy connected to the HTML structure)
const activeElement = useActiveElement();
onKeyStroke("ArrowDown", (e) => {
  // next parent link or next child link
  if (
    activeElement.value?.parentElement?.nextElementSibling?.getElementsByTagName(
      "a",
    )[0]
  ) {
    e.preventDefault();
    activeElement.value.parentElement.nextElementSibling
      .getElementsByTagName("a")[0]
      .focus();
    return;
  }
});

onKeyStroke("ArrowUp", (e) => {
  // previous parent link or previous child link
  if (
    activeElement.value?.parentElement?.previousElementSibling?.getElementsByTagName(
      "a",
    )[0]
  ) {
    e.preventDefault();
    activeElement.value?.parentElement.previousElementSibling
      .getElementsByTagName("a")[0]
      .focus();
    return;
  }
});

//#endregion - keyboard navigation

// reset when route.path changes
watch(
  () => route.path,
  () => {
    if (resetActiveClass.value == true && navigationElements.value) {
      resetNavigationActiveClass(navigationElements.value);
    }
    resetActiveClass.value = true;
    currentMenuPosition.value = undefined;
    emit('close')
  },
);

const setMenuSize = () => {
  const menuContainer = document.querySelector('.main-menu .container');
  const spanBefore = document.querySelectorAll('.main-menu-bg .span-before');
  const spanAfter = document.querySelectorAll('.main-menu-bg .span-after');

  if (menuContainer && spanBefore && spanAfter) {
    const menuSize = ((window.innerWidth - menuContainer.clientWidth) / 2) + 15;

    for (var i = 0; i < spanBefore.length; i++) {
      spanBefore[i].style.width = menuSize + 'px';
      spanAfter[i].style.width = menuSize + 'px';
    }
  }
};

// Before unmount equivalent
onBeforeUnmount(() => {
  window.removeEventListener('resize', setMenuSize);
});
</script>

<template>
  <!-- eslint-disable vue/no-v-html -->
  <ul class="md:flex items-center md:space-x-5 text-sm lg:text-base main-menu-list">
    <template v-for="(navigationElement, index) in navigationElements"
              :key="navigationElement.id"
              @mouseover="currentMenuPosition = navigationElement.id"
              @focusin="currentMenuPosition = navigationElement.id">
      <li class="menu-line" :class="{
      'active': (navigationElement as NavigationElement).activeClass,
      'text-red-500': (navigationElement as NavigationElement).id === '01912be482b17685bb7bb5bd21601803',
      }"  @mouseover="setMenuSize">
        <NuxtLink
            role="menuitem"
            :target="
              navigationElement.externalLink || navigationElement.linkNewTab
                ? '_blank'
                : ''
            "
            :to="formatLink(getCategoryRoute(navigationElement))"
            class="uppercase"
            @click="updateActiveClass(navigationElement.id, navigationElement.parentId)"
        >
          {{ getTranslatedProperty(navigationElement, "name") }}
        </NuxtLink>

        <client-only>
          <ul v-show="navigationElement.children.length > 0"
               class="sub-menu md:absolute start-4 top-full py-1 bg-white text-black03 w-full md:flex justify-between main-menu-bg"
               @click="currentMenuPosition = navigationElement.id;"
               @mouseleave="currentMenuPosition = undefined">

            <li class="w-full py-1">
              <ul class="relative flex flex-col gap-3 sub-menu-in">
                <ul class=" top-0 w-full w-sa border-[#D9D9D9] ps-8 flex gap-3">
                  <li class="w-[50%]">
                    <ul class="flex flex-col gap-1">
                      <template v-if="navigationElement.children.length > 0">
                        <LayoutTopNavigationRecursiveMobile
                            :navigation-element-children="navigationElement.children"
                            row="1"
                            @update-active-class="onUpdateActiveClass"
                            @focusout-last-element="currentMenuPosition = undefined"
                        />
                      </template>
                    </ul>
                  </li>
                  <li class="w-[50%]">
                    <ul class="flex flex-col gap-1">
                      <template v-if="navigationElement.children.length > 0">
                        <LayoutTopNavigationRecursiveMobile
                            :navigation-element-children="navigationElement.children"
                            row="2"
                            @update-active-class="onUpdateActiveClass"
                            @focusout-last-element="currentMenuPosition = undefined"
                        />
                      </template>
                    </ul>
                  </li>
                </ul>
              </ul>
            </li>
          </ul>
        </client-only>
      </li>
    </template>

    <template v-for="(navigationElement, index) in subCategories">
        <li class="menu-line" :class="{
      'active': (navigationElement as NavigationElement).activeClass,
      }"  @mouseover="setMenuSize">
          <NuxtLink
              role="menuitem"
              :target="
              navigationElement.externalLink || navigationElement.linkNewTab
                ? '_blank'
                : ''
            "
              :to="formatLink(getCategoryRoute(navigationElement))"
              class="uppercase"
              @click="updateActiveClass(navigationElement.id, navigationElement.parentId)"
          >
            {{ getTranslatedProperty(navigationElement, "name") }}
          </NuxtLink>

          <client-only>
            <ul v-show="navigationElement.children && navigationElement.children.length > 0"
                class="sub-menu md:absolute start-4 top-full py-3 bg-white text-black03 w-full md:flex justify-between main-menu-bg"
                @click="currentMenuPosition = navigationElement.id;"
                @mouseleave="currentMenuPosition = undefined">
              <li class="w-full py-1">
                <ul class="relative flex flex-col gap-3 sub-menu-in">
                  <ul class=" top-0 w-full w-sa border-[#D9D9D9] ps-8 flex gap-3">
                    <li class="w-[50%]">
                      <ul class="flex flex-col gap-2">
                        <template v-if="navigationElement.children && navigationElement.children.length > 0">
                          <LayoutTopNavigationRecursiveMobile
                              :navigation-element-children="navigationElement.children"
                              row="1"
                              @update-active-class="onUpdateActiveClass"
                              @focusout-last-element="currentMenuPosition = undefined"
                          />
                        </template>
                      </ul>
                    </li>
                    <li class="w-[50%]">
                      <ul class="flex flex-col gap-2">
                        <template v-if="navigationElement.children && navigationElement.children.length > 0">
                          <LayoutTopNavigationRecursiveMobile
                              :navigation-element-children="navigationElement.children"
                              row="2"
                              @update-active-class="onUpdateActiveClass"
                              @focusout-last-element="currentMenuPosition = undefined"
                          />
                        </template>
                      </ul>
                    </li>
                  </ul>
                </ul>
              </li>
            </ul>
          </client-only>
        </li>
    </template>
  </ul>
</template>

<style scoped>

</style>
