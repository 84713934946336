import { createGtm } from "@gtm-support/vue-gtm"

export default defineNuxtPlugin((nuxtApp)=> {
    nuxtApp.vueApp.use(createGtm({
        id: 'GTM-5XZ8L32M', // Your GTM single container ID, array of container ids
        defer: true, // Script can be set to `defer` to speed up page load at the cost of less accurate results.
        compatibility: false, // Will add `async` and `defer` to the script tag to not block requests for old browsers that do not support `async`
        // nonce: '2726c7f26c', // Will add `nonce` to the script tag
        enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
        debug: true, // Whether display console logs debugs (optional)
        loadScript: true, // Whether to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
        vueRouter: useRouter(), // Pass the router instance to automatically sync with router (optional)
        trackOnNextTick: false,
    }))
})