<script setup lang="ts">
import { ref } from "vue";
import {useCachedCmsElementsFromCategory} from "~/components/server/useCachedCmsElementsFromCategory";

const layoutId = "01912bef017974b183a7262d9e552287"

let cmsElementsFromCategory = ref();

cmsElementsFromCategory.value = await useCachedCmsElementsFromCategory(layoutId);

/*
function appendScriptToElement(scriptSrc: string, elementId: string) :void {
  const scriptElement = document.createElement('script');
  scriptElement.setAttribute('src', scriptSrc);
  scriptElement.setAttribute("defer", "defer");
  document.getElementById(elementId).appendChild(scriptElement);
}

onMounted( ()=> {
  appendScriptToElement('https://app.addsauce.com/widgets/highlight_reels/f0ba9a87-9453-1fe5-cff8-9a7c99809711/sauce_general.js', 'snapppt-widget-glob');
})
*/
</script>
<template>
  <CmsPage v-if="cmsElementsFromCategory?.cmsPage" :content="cmsElementsFromCategory.cmsPage" />
  <!--div id="snapppt-widget-glob"></div-->
</template>