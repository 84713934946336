import LocalTranslations from "~/i18n/fr-FR/fr-FR";
import {flattenObject} from "~/i18n/src/helpers/flatten";
export default defineI18nLocale(async (locale:string) => {
    return $fetch(`/api/translations/${locale}`).then((response) => {
        let AllTranslations = {...flattenObject(LocalTranslations), ...response};

        if (get('lang_debug')) {
            return {};
        }

        return AllTranslations;
    });
})

function get(name:string){
    let result = (new RegExp('[?&]'+encodeURIComponent(name)+'=([^&]*)')).exec(location?.search);
    if(result) {
        return decodeURIComponent(result[1]);
    }
}