import account from "~/i18n/fr-FR/account.json";
import form from "~/i18n/fr-FR/form.json";
import changePassword from "~/i18n/fr-FR/changePassword.json";
import recoveryPassword from "~/i18n/fr-FR/recoveryPassword.json";
import checkout from "~/i18n/fr-FR/checkout.json";
import general from "~/i18n/fr-FR/general.json";
import cart from "~/i18n/fr-FR/cart.json";
import listing from "~/i18n/fr-FR/listing.json";
import product from "~/i18n/fr-FR/product.json";
import newsletter from "~/i18n/fr-FR/newsletter.json";
import validations from "~/i18n/fr-FR/validations.json";
import errors from "~/i18n/fr-FR/errors.json";

export default {
  ...account,
  ...form,
  ...changePassword,
  ...recoveryPassword,
  ...checkout,
  ...general,
  ...cart,
  ...listing,
  ...product,
  ...newsletter,
  ...validations,
  ...errors,
};
