import axios from "axios";

export async function useCachedPaymentMethods() {
    const storeApiUrl = getOrigin() + "/api/paymentMethods";
    const response = await axios.get(storeApiUrl);

    return response.data;
}

function getOrigin(): string | undefined {
    let origin = "";
    const nuxtApp = useNuxtApp();
    const isDeV = process.env.NODE_ENV !== 'production';

    if (import.meta.server) {
        if (nuxtApp.ssrContext?.event.node.req.headers.host !== "undefined") {
            const location = isDeV ? "http://" : "https://"; // in dev mode we need to use http
            const host = nuxtApp.ssrContext?.event.node.req.headers.host;
            origin = location + host
        }
    } else {
        origin = window.location.origin;
    }

    return origin;
}