import Cookies from "js-cookie";

export async function useHelloAgainSSO() {
    const {
        refreshSessionContext
    } = useSessionContext();

    const runtimeConfig = useRuntimeConfig();

    const shopwareEndpoint =
        runtimeConfig.public?.shopware?.endpoint ??
        runtimeConfig.public?.shopware?.shopwareEndpoint;
    const shopwareAccessToken =
        runtimeConfig.public?.shopware?.accessToken ??
        runtimeConfig.public?.shopware?.shopwareAccessToken;

    if (import.meta.client) {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const token = urlParams.get('helloagain_token');
        if (token) {
            const url = shopwareEndpoint + `v1/get-user-by-token`;

            try {
                // DO A CALL OF Get User By External Token
                const res = await $fetch<{ contextToken: string }>(url, {
                    method: 'POST',
                    headers: {
                        'Sw-Access-Key': shopwareAccessToken as string,
                        'Sw-Context-Token': Cookies.get('sw-context-token') as string,
                        'Content-Type': 'application/json',
                        'sw-include-seo-urls': 'true' // Include any necessary headers
                    },
                    body: {
                        "helloagain_token": token
                    }
                });
                if(res && res.contextToken) {
                    Cookies.set('sw-context-token', res.contextToken as string)
                    await refreshSessionContext();
                    window.location.href ="/";
                }
            } catch (error) {
                console.log('error:', 'No valid token.');
            }

        }
    }
}