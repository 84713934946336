<script setup lang="ts">
import type {Schemas} from "#shopware";
import {getCategoryRoute, getTranslatedProperty} from "@shopware/helpers";

const { count } = useCart();
const { count: wishlistCount } = useWishlist();
const localePath = useLocalePath();
const { formatLink } = useInternationalization(localePath);

const navigationElements = inject("swNavigation-service-navigation");

const filteredNavigations = computed(() => {
  if(navigationElements) {
    const navigation: Schemas["Category"][] = JSON.parse(
        JSON.stringify(navigationElements.value),
    );
    return navigation;
  }
  return [];
});

const miniCartModal = useMiniCartModal();
</script>

<template>
  <div class="md:block head_right pt-3 md:pt-0">
    <div class="md:flex items-center justify-self-start justify-items-start mb-6 md:space-x-5">
      <ul class="md:flex items-center md:space-x-5 md:text-sm">
        <li class="px-4 md:p-0" v-for="navigationElement in filteredNavigations" :key="navigationElement.id">
          <NuxtLink :target="
                        navigationElement.externalLink || navigationElement.linkNewTab
                          ? '_blank'
                          : ''
                      "
                    :to="formatLink(getCategoryRoute(navigationElement))"
                    class="hover:text-pink py-1 md:p-0 block">
            {{ getTranslatedProperty(navigationElement, "name") }}
          </NuxtLink>
        </li>
      </ul>
      <div class="lang-sec px-4 pt-2 md:p-0 justify-self-start">
        <LanguageSwitcher type="mobile" />
      </div>
    </div>
    <div class="md:flex items-center justify-end md:space-x-5 md:gap-0">
      <div class="px-4 pb-5 md:p-0 hidden md:block">
        <form class="relative">
          <span class="absolute top-[50%] translate-y-[-50%] left-3">
            <i class="fa-solid fa-magnifying-glass text-black03"></i>
          </span>
          <LayoutStoreSearch />
        </form>
      </div>
      <div class="px-4 md:p-0">
        <ul class="flex items-start space-x-5 text-xl md:text-base">
          <li class="hidden md:block">
            <button
                class="group bg-transparent -m-2 p-2 flex items-center relative"
                aria-label="Mini cart"
                data-testid="cart-button"
                @click="miniCartModal.open"
            >
              <i class="fa-solid fa-bag-shopping"></i>

              <span v-if="count" class="w-4 h-4 rounded-full font-semibold text-[11px] bg-pink font-inter flex items-center justify-center text-black02 absolute right-[-5px] top-[-1px]">{{ count || "" }}</span>
            </button>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

