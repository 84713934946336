
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as _91_46_46_46all_930i5ctWJQq5Meta } from "/vercel/path0/pages/[...all].vue?macro=true";
import { default as addressED39XkEx1LMeta } from "/vercel/path0/pages/account/address.vue?macro=true";
import { default as indexqOnLrLMUjLMeta } from "/vercel/path0/pages/account/index.vue?macro=true";
import { default as orderTLe4nSwR9NMeta } from "/vercel/path0/pages/account/order.vue?macro=true";
import { default as paymentV3pkwItuAdMeta } from "/vercel/path0/pages/account/payment.vue?macro=true";
import { default as profileLQWMAtlTUmMeta } from "/vercel/path0/pages/account/profile.vue?macro=true";
import { default as indexR9ItFM3MR2Meta } from "/vercel/path0/pages/account/recover/index.vue?macro=true";
import { default as passwordn5BdmkURowMeta } from "/vercel/path0/pages/account/recover/password.vue?macro=true";
import { default as cartfC7LJBHqlCMeta } from "/vercel/path0/pages/checkout/cart.vue?macro=true";
import { default as indexraiq4bUafSMeta } from "/vercel/path0/pages/checkout/index.vue?macro=true";
import { default as indexDdll4vfR4JMeta } from "/vercel/path0/pages/checkout/success/[id]/index.vue?macro=true";
import { default as paidNswHEDxk6GMeta } from "/vercel/path0/pages/checkout/success/[id]/paid.vue?macro=true";
import { default as unpaid61KGWjv8ShMeta } from "/vercel/path0/pages/checkout/success/[id]/unpaid.vue?macro=true";
import { default as authorize6naFMlkDajMeta } from "/vercel/path0/pages/hla/authorize.vue?macro=true";
import { default as logint0AWlhQgM0Meta } from "/vercel/path0/pages/login.vue?macro=true";
import { default as newsletter_45subscribep2E1MYmvn4Meta } from "/vercel/path0/pages/newsletter-subscribe.vue?macro=true";
import { default as registerIikvtlfpbHMeta } from "/vercel/path0/pages/register.vue?macro=true";
import { default as reset_45password7wK2GPZgVKMeta } from "/vercel/path0/pages/reset-password.vue?macro=true";
import { default as searchBIFC2dE21WMeta } from "/vercel/path0/pages/search.vue?macro=true";
import { default as sentry_45testGeaffJTu8bMeta } from "/vercel/path0/pages/sentry-test.vue?macro=true";
import { default as shopwareRPK9liJ28YMeta } from "/vercel/path0/pages/shopware.vue?macro=true";
import { default as wishlistakmJn3oDDeMeta } from "/vercel/path0/pages/wishlist.vue?macro=true";
export default [
  {
    name: "all___fr-FR",
    path: "/fr-FR/:all(.*)*",
    component: () => import("/vercel/path0/pages/[...all].vue")
  },
  {
    name: "all___de-DE",
    path: "/:all(.*)*",
    component: () => import("/vercel/path0/pages/[...all].vue")
  },
  {
    name: "account-address___fr-FR",
    path: "/fr-FR/account/address",
    meta: addressED39XkEx1LMeta || {},
    component: () => import("/vercel/path0/pages/account/address.vue")
  },
  {
    name: "account-address___de-DE",
    path: "/account/address",
    meta: addressED39XkEx1LMeta || {},
    component: () => import("/vercel/path0/pages/account/address.vue")
  },
  {
    name: "account___fr-FR",
    path: "/fr-FR/account",
    meta: indexqOnLrLMUjLMeta || {},
    component: () => import("/vercel/path0/pages/account/index.vue")
  },
  {
    name: "account___de-DE",
    path: "/account",
    meta: indexqOnLrLMUjLMeta || {},
    component: () => import("/vercel/path0/pages/account/index.vue")
  },
  {
    name: "account-order___fr-FR",
    path: "/fr-FR/account/order",
    meta: orderTLe4nSwR9NMeta || {},
    component: () => import("/vercel/path0/pages/account/order.vue")
  },
  {
    name: "account-order___de-DE",
    path: "/account/order",
    meta: orderTLe4nSwR9NMeta || {},
    component: () => import("/vercel/path0/pages/account/order.vue")
  },
  {
    name: "account-payment___fr-FR",
    path: "/fr-FR/account/payment",
    meta: paymentV3pkwItuAdMeta || {},
    component: () => import("/vercel/path0/pages/account/payment.vue")
  },
  {
    name: "account-payment___de-DE",
    path: "/account/payment",
    meta: paymentV3pkwItuAdMeta || {},
    component: () => import("/vercel/path0/pages/account/payment.vue")
  },
  {
    name: "account-profile___fr-FR",
    path: "/fr-FR/account/profile",
    meta: profileLQWMAtlTUmMeta || {},
    component: () => import("/vercel/path0/pages/account/profile.vue")
  },
  {
    name: "account-profile___de-DE",
    path: "/account/profile",
    meta: profileLQWMAtlTUmMeta || {},
    component: () => import("/vercel/path0/pages/account/profile.vue")
  },
  {
    name: "account-recover___fr-FR",
    path: "/fr-FR/account/recover",
    component: () => import("/vercel/path0/pages/account/recover/index.vue")
  },
  {
    name: "account-recover___de-DE",
    path: "/account/recover",
    component: () => import("/vercel/path0/pages/account/recover/index.vue")
  },
  {
    name: "account-recover-password___fr-FR",
    path: "/fr-FR/account/recover/password",
    component: () => import("/vercel/path0/pages/account/recover/password.vue")
  },
  {
    name: "account-recover-password___de-DE",
    path: "/account/recover/password",
    component: () => import("/vercel/path0/pages/account/recover/password.vue")
  },
  {
    name: "checkout-cart___fr-FR",
    path: "/fr-FR/checkout/cart",
    component: () => import("/vercel/path0/pages/checkout/cart.vue")
  },
  {
    name: "checkout-cart___de-DE",
    path: "/checkout/cart",
    component: () => import("/vercel/path0/pages/checkout/cart.vue")
  },
  {
    name: "checkout___fr-FR",
    path: "/fr-FR/checkout",
    meta: indexraiq4bUafSMeta || {},
    component: () => import("/vercel/path0/pages/checkout/index.vue")
  },
  {
    name: "checkout___de-DE",
    path: "/checkout",
    meta: indexraiq4bUafSMeta || {},
    component: () => import("/vercel/path0/pages/checkout/index.vue")
  },
  {
    name: "checkout-success-id___fr-FR",
    path: "/fr-FR/checkout/success/:id()",
    component: () => import("/vercel/path0/pages/checkout/success/[id]/index.vue")
  },
  {
    name: "checkout-success-id___de-DE",
    path: "/checkout/success/:id()",
    component: () => import("/vercel/path0/pages/checkout/success/[id]/index.vue")
  },
  {
    name: "checkout-success-id-paid___fr-FR",
    path: "/fr-FR/checkout/success/:id()/paid",
    component: () => import("/vercel/path0/pages/checkout/success/[id]/paid.vue")
  },
  {
    name: "checkout-success-id-paid___de-DE",
    path: "/checkout/success/:id()/paid",
    component: () => import("/vercel/path0/pages/checkout/success/[id]/paid.vue")
  },
  {
    name: "checkout-success-id-unpaid___fr-FR",
    path: "/fr-FR/checkout/success/:id()/unpaid",
    component: () => import("/vercel/path0/pages/checkout/success/[id]/unpaid.vue")
  },
  {
    name: "checkout-success-id-unpaid___de-DE",
    path: "/checkout/success/:id()/unpaid",
    component: () => import("/vercel/path0/pages/checkout/success/[id]/unpaid.vue")
  },
  {
    name: "hla-authorize___fr-FR",
    path: "/fr-FR/hla/authorize",
    component: () => import("/vercel/path0/pages/hla/authorize.vue")
  },
  {
    name: "hla-authorize___de-DE",
    path: "/hla/authorize",
    component: () => import("/vercel/path0/pages/hla/authorize.vue")
  },
  {
    name: "login___fr-FR",
    path: "/fr-FR/login",
    component: () => import("/vercel/path0/pages/login.vue")
  },
  {
    name: "login___de-DE",
    path: "/login",
    component: () => import("/vercel/path0/pages/login.vue")
  },
  {
    name: "newsletter-subscribe___fr-FR",
    path: "/fr-FR/newsletter-subscribe",
    component: () => import("/vercel/path0/pages/newsletter-subscribe.vue")
  },
  {
    name: "newsletter-subscribe___de-DE",
    path: "/newsletter-subscribe",
    component: () => import("/vercel/path0/pages/newsletter-subscribe.vue")
  },
  {
    name: "register___fr-FR",
    path: "/fr-FR/register",
    component: () => import("/vercel/path0/pages/register.vue")
  },
  {
    name: "register___de-DE",
    path: "/register",
    component: () => import("/vercel/path0/pages/register.vue")
  },
  {
    name: "reset-password___fr-FR",
    path: "/fr-FR/reset-password",
    component: () => import("/vercel/path0/pages/reset-password.vue")
  },
  {
    name: "reset-password___de-DE",
    path: "/reset-password",
    component: () => import("/vercel/path0/pages/reset-password.vue")
  },
  {
    name: "search___fr-FR",
    path: "/fr-FR/search",
    component: () => import("/vercel/path0/pages/search.vue")
  },
  {
    name: "search___de-DE",
    path: "/search",
    component: () => import("/vercel/path0/pages/search.vue")
  },
  {
    name: "sentry-test___fr-FR",
    path: "/fr-FR/sentry-test",
    component: () => import("/vercel/path0/pages/sentry-test.vue")
  },
  {
    name: "sentry-test___de-DE",
    path: "/sentry-test",
    component: () => import("/vercel/path0/pages/sentry-test.vue")
  },
  {
    name: "shopware___fr-FR",
    path: "/fr-FR/shopware",
    component: () => import("/vercel/path0/pages/shopware.vue")
  },
  {
    name: "shopware___de-DE",
    path: "/shopware",
    component: () => import("/vercel/path0/pages/shopware.vue")
  },
  {
    name: "wishlist___fr-FR",
    path: "/fr-FR/wishlist",
    component: () => import("/vercel/path0/pages/wishlist.vue")
  },
  {
    name: "wishlist___de-DE",
    path: "/wishlist",
    component: () => import("/vercel/path0/pages/wishlist.vue")
  }
]