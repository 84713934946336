<script setup lang="ts">
import type { Schemas } from "#shopware";
import { getPrefix } from "./i18n/src/helpers/prefix";
import { useHistoryNavigation } from '~/composables/useHistoryNavigation'
const config = useRuntimeConfig();

useHelloAgainSSO();

/**
 * Init breadcrumbs context
 */
useBreadcrumbs();

useHead({
  title: config.public.general.shopTitle,
  meta: [{ name: config.public.general.name, content: config.public.general.description }],
  htmlAttrs: {
    lang: config.public.general.language,
  },
  bodyAttrs: {
    class: "def_body_sty"
  }
});

const { apiClient } = useShopwareContext();
const sessionContextData = ref<Schemas["SalesChannelContext"]>();
const contextResponse = await apiClient.invoke("readContext get /context");
sessionContextData.value = contextResponse.data;

if (config.public.broadcasting) {
  useBroadcastChannelSync();
}

// If you enable runtimeConfig.shopware.useUserContextInSSR, then you can use this code to share session between server and client.
// const { data: sessionContextData } = await useAsyncData(
//   "sessionContext",
//   async () => {
//     return await getSessionContext(apiInstance);
//   }
// );

// read the locale/lang code from accept-language header (i.e. en, en-GB or de-DE)
// and set configuration for price formatting globally
const headers = useRequestHeaders();
// Extract the first (with highest priority order) locale or lang code from accept-language header
// for example: "en-US;q=0.7,en;q=0.3" will return "en-US"
const localeFromHeader = headers?.["accept-language"]
  ?.split(",")
  ?.map(
    (languageConfig) => languageConfig.match(/^([a-z]{2}(?:-[A-Z]{2})?)/)?.[0],
  )
  .find(Boolean);

usePrice({
  currencyCode: sessionContextData.value?.currency?.isoCode || "",
  localeCode: localeFromHeader,
});

useSessionContext(sessionContextData.value);

const { getWishlistProducts } = useWishlist();
const { refreshCart } = useCart();

useNotifications();
useAddress();

const { locale, availableLocales, defaultLocale, localeProperties, messages } =
  useI18n();
const router = useRouter();
const {
  getAvailableLanguages,
  getLanguageCodeFromId,
  getLanguageIdFromCode,
  changeLanguage,
  languages: storeLanguages,
} = useInternationalization();
const { languageIdChain, refreshSessionContext } = useSessionContext();

const { data: languages } = await useAsyncData("languages", async () => {
  return await getAvailableLanguages();
});
let languageToChangeId: string | null = null;

if (languages.value?.elements.length && router.currentRoute.value.name) {
  storeLanguages.value = languages.value?.elements;
  // Prefix from url
  const prefix = getPrefix(
    availableLocales,
    router.currentRoute.value.name as string,
    defaultLocale,
  );

  provide("cmsTranslations", messages.value[prefix || defaultLocale] ?? {});

  // Language set on the backend side
  if (localeProperties.value.localeId) {
    if (languageIdChain.value !== localeProperties.value.localeId) {
      languageToChangeId = localeProperties.value.localeId;
    }
  } else {
    const sessionLanguage = getLanguageCodeFromId(languageIdChain.value);

    // If languages are not the same, set one from prefix
    if (sessionLanguage !== prefix) {
      languageToChangeId = getLanguageIdFromCode(
        prefix ? prefix : defaultLocale,
      );
    }
  }

  if (languageToChangeId) {
    await changeLanguage(languageToChangeId);
    await refreshSessionContext();
  }

  locale.value = prefix ? prefix : defaultLocale;
  // Set prefix from CMS components
  provide("urlPrefix", prefix);

  useHead({
    htmlAttrs: {
      lang: locale.value,
    }
  });
}

if (import.meta.client) {
  // getting the wishlist products should not block SSR
  if (!(router.currentRoute.value.name as string).includes("wishlist")) {
    getWishlistProducts(); // initial page loading
  }
}

onMounted(() => {
  refreshCart();
});

const nuxtApp = useNuxtApp();
const isLoading = ref<boolean>(false);

// Initialize history navigation tracking
const { provideHistoryNavigation, historyState } = useHistoryNavigation()
 provideHistoryNavigation()

 nuxtApp.hook('page:start', () => {
   isLoading.value = true
   window.scrollTo(0, 0);
 })
 
 nuxtApp.hook('page:finish', () => {
   isLoading.value = false
   window.scrollTo(0, 0);
 })
</script>

<template>
  <div id="wrapper" class="overflow-x-hidden">
  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>
  </div>
  <!--LoadingOverlay :show="isLoading" /-->
</template>

<style>

</style>
