<script setup lang="ts">
import {computed} from "vue";

const props = defineProps<{
  controller: ReturnType<typeof useModal>;
}>();

const { cartItems, totalPrice, isEmpty, subtotal } = useCart();
const localePath = useLocalePath();
const { formatLink } = useInternationalization(localePath);

const freeAmount = 60.00;
const percent = computed(() => {
  if(subtotal.value > freeAmount){
    return 100;
  }
  return (subtotal.value / freeAmount) * 100;
});


</script>

<template>
  <LayoutSidebar :controller="props.controller" side="right">
    <aside class="flex-1 overflow-y-auto py-6 px-4 sm:px-6 mt-[100px] md:mt-0">
      <div class="flex items-start justify-between">
        <h2
          id="slide-over-title"
          class="text-lg font-medium text-secondary-900 py-0"
        >
          {{$t('cart.title')}}
        </h2>
        <div class="ml-3 flex h-7 items-center">
          <button
            type="button"
            class="-m-2 p-2 pt-2 pb-1 bg-transparent text-gray-500 hover:text-pink border"
            data-testid="cart-close-button"
            @click="props.controller.close"
          >
            <span class="sr-only">Close panel</span>
            <div class="w-6 h-6 fa-solid fa-close" />
          </button>
        </div>
      </div>

      <div class="mt-4">

        <div class="text-left px-4 mb-4">
          <div class="mt-5 relative max-w-full w-[560px] m-auto h-4 bg-cream">
            <div class="h-full absolute start-0 top-0 bg-pink" :style="'width: '+ percent +'%;'"></div>
          </div>
          <p :class="{'hidden' : percent >= 100}" class="font-bold text-pink">{{$t('cart.until')}} {{parseFloat(freeAmount - subtotal).toFixed(2)}} {{$t('cart.free.shipping')}}</p>
          <p :class="{'hidden' : percent < 100}" class="font-bold text-pink">{{$t('cart.free.shipping.valid')}}</p>
        </div>

        <div class="flow-root">
          <ul
            v-if="!isEmpty"
            role="list"
            class="-my-6 px-0 divide-y divide-secondary-200"
          >
            <li
              v-for="cartItem in cartItems"
              :key="cartItem.id"
              class="flex py-6"
            >
              <CheckoutCartItem :cart-item="cartItem" />
            </li>
          </ul>
          <div v-else class="text-2xl text-center">
            {{$t('cart.empty')}}
          </div>
        </div>
      </div>
    </aside>

    <aside
      aria-label="Side Cart Links"
      class="border-t border-secondary-200 py-6 px-4 sm:px-6"
    >
      <!-- <div class="flex items-center mb-2">
        <p class="w-full">
          <CheckoutPromotionCode />
        </p>
      </div> -->
      <div
        class="flex justify-between text-base font-medium text-secondary-900"
      >
        <p>{{ $t("cart.subtotal") }}</p>
        <SharedPrice :value="subtotal" data-testid="cart-subtotal" />
      </div>
      <p class="mt-2 text-sm text-secondary-500">
        {{ $t("cart.tax_info") }}
      </p>
      <div class=" mt-6 items-center justify-center text-center">
        <NuxtLink
          class="btn_secondary mb-2 m-auto min-w-[230px] md:max-w-full"
          :class="{
            '!text-[#bcc1c7] bg-[#f9f9f9] cursor-default': isEmpty,
            'text-white bg-primary  hover:bg-dark': !isEmpty,
          }"
          @click="props.controller.close"
          :to="formatLink(isEmpty ? '' : '/checkout')"
          data-testid="cart-checkout-link"
        >
          {{$t('cart.checkout.button')}}
        </NuxtLink>

        <NuxtLink
          class=" py-3 btn-outline m-auto w-full max-w-full"
          :to="formatLink(`/checkout/cart`)"
          data-testid="cart-checkout-shopping-cart"
          @click="props.controller.close"
        >
          {{$t('cart.cart.button')}}
        </NuxtLink>
      </div>
      <!-- <div class="mt-6 flex justify-center text-center text-sm text-dark">
        <p>
          {{$t('cart.or')}}
          <a
            href="javascript:history.back()"
            class="btn-outline px-4"
            data-testid="cart-continue-button"
            @click="props.controller.close"
          >
            {{$t('cart.continue_shopping.button')}}
          </a>
        </p>
      </div> -->
    </aside>
  </LayoutSidebar>
</template>
