import axios from "axios";

import getUrlPrefix from "~/components/cms/helpers/getUrlPrefix";

export async function useCachedCmsElementsFromCategoryWithChilds(categoryId: string) {
    const prefix = getUrlPrefix();
    let languageId = '';
    const {
        getLanguageIdFromCode,
    } = useInternationalization();

    try {
        languageId = getLanguageIdFromCode(
            prefix ? prefix : 'de-DE',
        );
    } catch (error) {
        languageId = "2fbb5fe2e29a4d70aa5854ce7ce3e20b";
    }


    const storeApiUrl = getOrigin() + "/api/front/" + languageId + "/cms/elements/category/childs/" + categoryId;
    const response = await axios.post(storeApiUrl);

    return response.data;
}

function getOrigin(): string | undefined {
    let origin = "";
    const nuxtApp = useNuxtApp();
    const isDeV = process.env.NODE_ENV !== 'production';

    if (import.meta.server) {
        if (nuxtApp.ssrContext?.event.node.req.headers.host !== "undefined") {
            const location = isDeV ? "http://" : "https://"; // in dev mode we need to use http
            const host = nuxtApp.ssrContext?.event.node.req.headers.host;
            origin = location + host
        }
    } else {
        origin = window.location.origin;
    }

    return origin;
}