<script setup lang="ts">
import {
  getTranslatedProperty,
  getCategoryRoute,
} from "@shopware/helpers";

import {useCachedPaymentMethods} from "~/components/server/useCachedPaymentMethods";
import {
  useCachedCmsElementsFromCategoryWithChilds
} from "~/components/server/useCachedCmsElementsFromCategoryWithChilds";

const { navigationElements } = useNavigation({ type: "footer-navigation" });
const localePath = useLocalePath();
const { formatLink } = useInternationalization(localePath);

const payments = await useCachedPaymentMethods();

const categoryId = "01944084030a79019d6e5c9888c6cd6d";
const categoryData = await useCachedCmsElementsFromCategoryWithChilds(categoryId);
const subCategories = categoryData[0].children;

</script>

<template>
  <LayoutCustomFooter />

  <footer>
    <div class="py-14 ">
      <div class="container">
        <div class="flex flex-wrap gap-y-3 lg:gap-y-5 lg:gap-1 lg:justify-between">
          <div class="hover_f_menu w-full sm:w-1/3 md:w-1/4 lg:w-auto sm:pe-4"
              v-for="navigationElement in navigationElements"
              :key="navigationElement.id"
          >
            <h5 class="mb-3 lg:mb-5">{{ getTranslatedProperty(navigationElement, "name") }}</h5>
            <template v-if="navigationElement.childCount > 0">
              <ul class="font-light text-sm leading-7">
                <li
                    v-for="navigationChild in navigationElement.children"
                    :key="navigationChild.id"
                    class="pb-3 md:pb-1"
                >
                  <NuxtLink
                      :target="navigationChild.linkNewTab
                      ? '_blank'
                      : ''
                  "
                      :to="formatLink(getCategoryRoute(navigationChild))"
                      class="text-base font-normal text-secondary-500 hover:text-secondary-900"
                  >
                    {{ getTranslatedProperty(navigationChild, "name") }}
                  </NuxtLink>
                </li>
              </ul>
            </template>
          </div>

          <div class="w-full sm:w-1/3 md:w-1/4 lg:w-auto sm:pe-4">
            <h5 class="mb-3 lg:mb-5">{{$t('footer.headline.payment')}}</h5>
            <div class="grid grid-cols-3 sm:grid-cols-2 items-center pb-3 md:pb-1">
              <div class="w-[80px]" v-if="payments" v-for="singlePaymentMethod in payments">
                <img v-if="singlePaymentMethod.media" :src="singlePaymentMethod.media.url" :alt="singlePaymentMethod.translated.name" class="mb-2" height="23" />
              </div>
            </div>
          </div>
          <div class="hover_f_menu w-full sm:w-1/3 md:w-1/4 lg:w-auto sm:pe-4 pb-3 md:pb-1">
            <h5 class="mb-3 lg:mb-5">{{$t('footer.headline.contact')}}</h5>
            <p class="text-base font-normal leading-7" v-html="$t('footer.text.contact')"></p>
            <p class="text-base font-normal leading-7"><a href="mailto:service@zebrafashion.com">service@zebrafashion.com</a></p>
          </div>
          <div class="hover_f_menu w-full sm:w-1/3 md:w-1/4 lg:w-auto sm:pe-4 pb-3 md:pb-1">
            <h5 class="mb-3 lg:mb-5">{{$t('footer.headline.socials')}}</h5>
            <ul class="flex space-x-3 text-sm mb-5 lg:mb-9">
              <li><a :href="$t('footer.facebook.link')" target="_blank" class="w-6 h-6 rounded-full flex items-center justify-center text-white bg-black03"><i class="fa-brands fa-facebook-f"></i></a></li>
              <li><a :href="$t('footer.instagram.link')" target="_blank" class="w-6 h-6 rounded-full flex items-center justify-center text-white bg-black03"><i class="fa-brands fa-instagram"></i></a></li>
              <li><a href="https://www.tiktok.com/@zebrafashion_official" target="_blank" class="w-6 h-6 rounded-full flex items-center justify-center text-white bg-black03"><i class="fa-brands fa-tiktok"></i></a></li>
            </ul>

            <h5 class="mb-3">{{$t('footer.headline.app')}}</h5>
            <div class="flex items-center flex-wrap space-x-2">
              <a :href="$t('footer.ios.link')" target="_blank" class="block"><NuxtImg format="webp" loading="lazy" src="/assets/images/app-store.png" alt="App Store" class="h-7" /></a>
              <a :href="$t('footer.android.link')" target="_blank" class="block"><NuxtImg format="webp" loading="lazy" src="/assets/images/google-play.png" alt="Google Play" class="h-7" /></a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bg-black03 py-3 md:py-2 text-white">
      <div class="container xl:ps-[100px]">
        <ul class="flex flex-wrap gap-y-3 gap-7 text-sm hover_f_menu">
          <li>© Copyright 2025</li>
          <template v-if="subCategories" v-for="nav in subCategories">
          <li>
            <NuxtLink
                role="menuitem"
                :target="nav.linkNewTab
                ? '_blank'
                : ''
            "
                :to="formatLink(getCategoryRoute(nav))"
            >
              {{ getTranslatedProperty(nav, "name") }}
            </NuxtLink>
          </li>
          </template>
        </ul>
      </div>
    </div>
  </footer>
</template>
