<script setup lang="ts">
import {
  getCategoryRoute,
  getTranslatedProperty,
} from "@shopware/helpers";
import type { Schemas } from "#shopware";
type NavigationElement = Schemas["Category"] & {
  activeClass?: boolean;
};

const localePath = useLocalePath();
const { formatLink } = useInternationalization(localePath);

const props = defineProps<{
  navigationElementChildren: Array<NavigationElement>;
  row: any;
}>();

const emits = defineEmits<{
  (
    e: "updateActiveClass",
    navigationId: string,
    parentId: string | undefined,
  ): void;
  (e: "focusoutLastElement", lastElement: boolean): void;
}>();

const emitUpdateActiveClass = (
  navigationId: string,
  parentId: string | undefined,
) => {
  emits("updateActiveClass", navigationId, parentId);
};

const count = Math.ceil(props.navigationElementChildren.length / 3);

const start = props.row == 1 ? 0 : (count * (props.row - 1));
const end = props.row != 3 ? start + count - 1 : start + count;

</script>

<template>
  <template
    v-for="(childElement, index) in navigationElementChildren"
    :key="childElement.id"
  >
    <li    v-if="index >= start && index <= end">
      <NuxtLink
        :to="formatLink(getCategoryRoute(childElement))"
        :target="childElement.externalLink || childElement.linkNewTab ? '_blank' : ''"
        :class="{'active': childElement.activeClass,}"
        @click="emitUpdateActiveClass(childElement.id, childElement.parentId)"
      >
        {{ getTranslatedProperty(childElement, "name") }}
      </NuxtLink>
      <template
        v-if="childElement.children && childElement.children.length > 0"
      >
        <LayoutTopNavigationRecursive
          :navigation-element-children="childElement.children"
          @update-active-class="emitUpdateActiveClass(childElement.id, childElement.parentId)"
        />
      </template>
    </li>
  </template>
</template>

<style scoped>

</style>
