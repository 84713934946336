<script setup lang="ts">
import { onClickOutside } from "@vueuse/core";

const { isLoggedIn, logout, user } = useUser();
const accountMenu = ref(null);
const router = useRouter();

const loginModalController = useModal();
const localePath = useLocalePath();
const { formatLink } = useInternationalization(localePath);
const isAccountMenuOpen = ref(false);

onClickOutside(accountMenu, () => (isAccountMenuOpen.value = false));

async function invokeLogout() {
  try {
    await logout();
  } finally {
    isAccountMenuOpen.value = false;
  }
}

async function invokeResetPassword() {
  await router.push(formatLink("/account/recover"));
  loginModalController.close();
}
</script>
<template>
  <div class="md:w-auto min-w-140px flex justify-end">
    <div class="my-account-area">

        <div v-show="!isLoggedIn">
          <button
            class="whitespace-nowrap bg-transparent text-base font-medium text-secondary-500 hover:text-secondary-900"
            data-testid="header-sign-in-link"
            @click="loginModalController.open"
          >
            <i class="fa-regular fa-user"></i>
          </button>
        </div>
        <SharedModal :controller="loginModalController">
          <AccountLoginForm
            @close="loginModalController.close"
            @success="loginModalController.close"
          >
            <div class="flex items-center justify-end">
              <div class="text-sm">
                <NuxtLink
                  class="font-medium text-black hover:text-pink cursor-pointer"
                  @click.stop="invokeResetPassword"
                >
                  {{$t('account.changePassword')}}
                </NuxtLink>
              </div>
            </div>
          </AccountLoginForm>
        </SharedModal>
        <div v-if="isLoggedIn">
          <div
            class="inset-y-2 flex items-center sm:static sm:inset-auto sm:ml-6 sm:pr-0"
          >
            <NuxtLink
              :to="formatLink('/account')"
              type="button"
              class="whitespace-nowrap bg-transparent text-base font-medium text-secondary-500 hover:text-secondary-900"
              data-testid="account-menu-hello-button"
            >
              <i class="fa-regular fa-user"></i>
            </NuxtLink>
          </div>
        </div>

    </div>
  </div>
</template>
