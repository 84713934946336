<script setup lang="ts">
import HeaderRight from './HeaderRight.vue';
import HeaderRightMobile from "~/components/layout/HeaderRightMobile.vue";
import LayoutTopNavigationMobile from "~/components/layout/LayoutTopNavigationMobile.vue";
import { useI18n } from 'vue-i18n';

const { count } = useCart();
const { count: wishlistCount } = useWishlist();
const localePath = useLocalePath();
const { formatLink } = useInternationalization(localePath);
const { t } = useI18n();

const miniCartModal = useMiniCartModal();
const openMobileMenu = ref(false);
const openMobileSearchMenu = ref(false);
const isSticky = ref(false);
const currentIndex = ref(0);

// Replace hardcoded topbarClaims with i18n translations
const topbarClaims = [
  t('topbar.claims.freeShipping'),
  t('topbar.claims.professionalEquipment'),
  t('topbar.claims.freeShippingHigher'),
  t('topbar.claims.fastDelivery'),
];

const goToSlide = (index: number) => {
  // Handle circular navigation
  if (index < 0) {
    currentIndex.value = topbarClaims.length - 1;
  } else if (index >= topbarClaims.length) {
    currentIndex.value = 0;
  } else {
    currentIndex.value = index;
  }
};

// Auto-rotate slides
let autoplayInterval: ReturnType<typeof setInterval>;

const startAutoplay = () => {
  autoplayInterval = setInterval(() => {
    goToSlide(currentIndex.value + 1);
  }, 5000);
};

const stopAutoplay = () => {
  clearInterval(autoplayInterval);
};

onMounted(() => {
  window.addEventListener('scroll', handleScroll);
  startAutoplay();
});

onUnmounted(() => {
  window.removeEventListener('scroll', handleScroll);
  stopAutoplay();
});

const handleScroll = () => {
  if (window.innerWidth >= 768) {
    isSticky.value = window.scrollY > 44;
  }
};
</script>

<template>
  <header :class="['header bg-black03 text-white relative z-50', { 'sticky': isSticky }]">
    <div class="top-header bg-black02 text-center py-[10px]">
      <div class="container">
        <div class="slider-container relative overflow-hidden">
          <transition-group 
            name="slide" 
            tag="div" 
            class="relative text-center"
          >
            <p 
              v-for="(claim, index) in topbarClaims" 
              :key="index"
              v-show="currentIndex === index"
              class="h-[24px]"
            >
              {{ claim }}
            </p>
          </transition-group>
        </div>
        
        <div class="button-wrapper absolute top-[10px] mx-auto left-[50%] translate-x-[-50%] w-[600px] max-w-[100%] h-[24px]">
          <!-- Navigation Arrows -->
          <button 
            class="topbar-prev absolute left-6 top-1/2 -translate-y-1/2 text-white opacity-50 hover:opacity-100 transition-opacity"
            @click="goToSlide(currentIndex - 1)"
            @mouseenter="stopAutoplay"
            @mouseleave="startAutoplay"
          >
            <i class="fa-solid fa-chevron-left text-sm"></i>
          </button>
          <button 
            class="topbar-next absolute right-6 top-1/2 -translate-y-1/2 text-white opacity-50 hover:opacity-100 transition-opacity"
            @click="goToSlide(currentIndex + 1)"
            @mouseenter="stopAutoplay"
            @mouseleave="startAutoplay"
          >
              <i class="fa-solid fa-chevron-right text-sm"></i>
            </button>
        </div>
      </div>
    </div>

    <div class="sticky-header">
      <div class="main-header pt-[10px] pb-4 lg:pb-5">
        <div class="container ">
          <div class="flex items-center justify-between w-100">
            <div class="logo  max-w-[135px] md:max-w-[186px]">
              <NuxtLink :to="formatLink(`/`)" class="block">
                <img class="max-w-[112px] md:max-w-full " src="/assets/images/logo-white.png" alt="Logo" />
              </NuxtLink>
            </div>
            <div class="flex justify-between gap-6">
              <div class="md:w-auto min-w-140px flex justify-end md:hidden group bg-transparent -m-2 p-2 flex items-center relative">
                <div class="search-hamburger md:hidden text-base" @click="openMobileSearchMenu = !openMobileSearchMenu;openMobileMenu = false;">
                  <i class="fa fa-magnifying-glass"></i>
                </div>
              </div>

              <div class="md:w-auto min-w-140px flex justify-end md:hidden group bg-transparent -m-2 p-2 flex items-center relative">
                <NuxtLink
                    class="relative"
                    aria-label="wishlist"
                    data-testid="wishlist-button"
                    :to="formatLink(`/wishlist`)"
                >
                  <i class="fa-regular fa-heart"></i>
                  <client-only>
                    <span v-if="wishlistCount" class="w-4 h-4 rounded-full font-semibold text-[11px] bg-pink font-inter flex items-center justify-center text-black02 absolute right-[-10px] top-[-7px]">{{ wishlistCount }}</span>
                  </client-only>
                </NuxtLink>
              </div>

              <AccountMenu class="md:hidden group bg-transparent -m-2 p-2 flex items-center relative" />

              <button
                  class="md:hidden group bg-transparent -m-2 p-2 flex items-center relative"
                  aria-label="Mini cart"
                  data-testid="cart-button"
                  @click="miniCartModal.open"
              >
                <i class="fa-solid fa-bag-shopping"></i>
                <span v-if="count" class="w-4 h-4 rounded-full font-semibold text-[11px] bg-pink font-inter flex items-center justify-center text-black02 absolute right-[-3px] top-[5px]">{{ count || "" }}</span>
              </button>

              <div class="menu-hamburger md:hidden text-lg" @click="openMobileMenu = !openMobileMenu;openMobileSearchMenu = false;">
                <i class="fa-solid fa-bars"></i>
              </div>
            </div>
            <HeaderRight class="hidden" />
          </div>
        </div>
      </div>
      <div class="main-menu hidden md:block">
        <div class="container relative">
          <LayoutTopNavigation />
        </div>
      </div>
    </div>

    <div v-show="openMobileMenu" class="menu-small-device pt-1 pb-4 bg-white text-black03 absolute top-full start-0 w-full">
      <LayoutTopNavigationMobile @close="openMobileMenu = false"/>
      <HeaderRightMobile />
    </div>

    <div v-if="openMobileSearchMenu" class="pt-1 pb-4 bg-black03 absolute top-full start-0 w-full">
      <div class="px-4 pb-5 md:p-0">
      <form class="relative">
          <span class="absolute top-[50%] translate-y-[-50%] left-3">
            <i class="fa-solid fa-magnifying-glass text-black03"></i>
          </span>
          <LayoutStoreSearch @close="openMobileSearchMenu = false" />
      </form>
      </div>
    </div>

    <CheckoutSideCart :controller="miniCartModal" />
  </header>
</template>

<style scoped>
.slider-container, .slider-container p {
  height: 24px;
}

/* Update the transition effects */
.slide-enter-active,
.slide-leave-active {
  transition: all 0.75s ease;
  position: absolute;
  width: 100%;
}

.slide-enter-from {
  transform: translateX(-100px);
  opacity: 0;
}

.slide-leave-to {
  transform: translateX(100px);
  opacity: 0;
}

/* Add positioning for the slider container */
.slider-container {
  position: relative;
}

.slider-container > div {
  position: relative;
  width: 100%;
  height: 100%;
}

.topbar-prev,
.topbar-next {
  z-index: 10;
  cursor: pointer;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 768px) {
  .topbar-prev {
    left: 10px;
  }
  .topbar-next {
    right: 10px;
  }
}
</style>